#news_articles {
  & > div > a {
    margin: 0px;
    width: 100%;

    & > *:not(:first-child):not(:last-child) {
      background-color: #0003;
    }
  }

  img {
    // border-radius: 0px !important;
    max-height: 10rem;
  }

  .news-preview-text {
    // background: #0005;
    // background: radial-gradient(#0003, transparent);
    font-weight: normal;
  }
}
